import { ApplicationRef, Injector, LOCALE_ID, NgModule } from '@angular/core';
import { AvanpostAuthProvider, CoreModule, CoreRouterHelper, CORE_CONFIG, CORE_EXTERNAL_AUTH_PROVIDER } from '@evolenta/core';
import { CoreModuleConfig, CoreModuleDynamicDepsConfigFactory } from './config/core-module.config';
import { BrowserModule } from '@angular/platform-browser';
import { registerLocaleData } from '@angular/common';
import { NgProgressModule } from 'ngx-progressbar';
import { CanDeactivateGuard } from './common/services/can-deactivate-guard.service';
import { AppComponent } from './app.component';
import { UtilityService } from './common/services/utility.service';
import { WindowWidth } from './common/services/window-width.service';
import { TreeSelectService } from './common/services/tree-select.service';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedComponentsModule } from './common/shared-components.module';
import { TicketService } from './common/services/ticket.service';
import { UserOrganizationsResolve } from './modules/user-organizations.resolve';
import { AdminModule } from './routing-modules/admin/admin.module';
import { SupervisorModule } from './routing-modules/supervisor/supervisor.module';
import { ReportsModule } from './routing-modules/reports/reports.module';
import { ProcessesModule } from './modules/processes/processes.module';
import { MetodologModule } from './routing-modules/metodolog/metodolog.module';
import { NsiModule } from './routing-modules/nsi/nsi.module';
import { SelectingModule } from './routing-modules/selecting/selecting.module';
import { LAYOUT_CONFIG } from '@evolenta/layout';
import { LayoutModuleDynamicDepsConfigFactory } from './config/layout-module.config';
import { CATALOGUES_CONFIG } from '@evolenta/catalogues';
import { CataloguesModuleDynamicDepsConfigFactory } from './config/catalogues-module.config';
import { FORMS_CONSTRUCTOR_CONFIG } from '@evolenta/forms-constructor';
import { FormsConstructorModuleDynamicDepsConfigFactory } from './config/forms-constructor-module.config';
import { ADDRESSES_CONFIG } from '@evolenta/addresses';
import { AddressesModuleDynamicDepsConfigFactory } from './config/addresses-module.config';
import { PRINTING_CONFIG } from '@evolenta/printing';
import { PrintingModuleDynamicDepsConfigFactory } from './config/printing-module.config';
import ru from '@angular/common/locales/ru';
import { DeveloperModule } from './routing-modules/developer/developer.module';
import { LicensingActivityModule } from './routing-modules/licensing-activity/licensing-activity.module';
import { SpoModule } from './routing-modules/spo/spo.module';
import { SYSTEM_TASKS_CONFIG } from '@evolenta/system-tasks-module';
import { SystemTasksModuleDynamicDepsConfigFactory } from './config/system-tasks-module.config';
import { BREADCRUMBS_CONFIG } from '@evolenta/breadcrumbs';
import { BreadcrumbsModuleDynamicDepsConfigFactory } from './config/breadcrumbs-module.config';
import { SIGNING_CONFIG } from '@evolenta/signing';
import { SigningModuleDynamicDepsConfigFactory } from './config/signing-module.config';
import { SupervisorRegisterModule } from './routing-modules/supervisor-register/supervisor-register.module';
import {
    LicensingActivityRegisterModule,
} from './routing-modules/licensing-activity-register/licensing-activity-register.module';

registerLocaleData(ru);

@NgModule({
    imports: [
        CoreModule.forRoot(),
        RouterModule.forRoot(CoreRouterHelper.createRootRoutes(CoreModuleConfig)),
        SharedComponentsModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        NgProgressModule,
        RouterModule,
        SelectingModule,
        SupervisorModule,
        SupervisorRegisterModule,
        AdminModule,
        ReportsModule,
        ProcessesModule,
        MetodologModule,
        NsiModule,
        DeveloperModule,
        LicensingActivityModule,
        LicensingActivityRegisterModule,
        SpoModule,
    ],
    declarations: [
        AppComponent,
    ],
    providers: [
        { provide: LOCALE_ID, useValue: 'ru' },
        { provide: CORE_CONFIG, useFactory: CoreModuleDynamicDepsConfigFactory, deps: [Injector] },
        { provide: CORE_EXTERNAL_AUTH_PROVIDER, useClass: AvanpostAuthProvider },
        { provide: LAYOUT_CONFIG, useFactory: LayoutModuleDynamicDepsConfigFactory, deps: [Injector] },
        { provide: CATALOGUES_CONFIG, useFactory: CataloguesModuleDynamicDepsConfigFactory, deps: [Injector] },
        { provide: FORMS_CONSTRUCTOR_CONFIG, useFactory: FormsConstructorModuleDynamicDepsConfigFactory, deps: [Injector, ApplicationRef] },
        { provide: ADDRESSES_CONFIG, useFactory: AddressesModuleDynamicDepsConfigFactory, deps: [Injector, ApplicationRef] },
        { provide: PRINTING_CONFIG, useFactory: PrintingModuleDynamicDepsConfigFactory, deps: [Injector] },
        { provide: SYSTEM_TASKS_CONFIG, useFactory: SystemTasksModuleDynamicDepsConfigFactory, deps: [Injector] },
        { provide: SIGNING_CONFIG, useFactory: SigningModuleDynamicDepsConfigFactory, deps: [Injector, ApplicationRef] },
        { provide: BREADCRUMBS_CONFIG, useFactory: BreadcrumbsModuleDynamicDepsConfigFactory, deps: [Injector] },
        UtilityService,
        WindowWidth,
        TicketService,
        TreeSelectService,
        CanDeactivateGuard,
        UserOrganizationsResolve,
    ],
    bootstrap: [
        AppComponent,
    ],
})
export class AppModule {
}
