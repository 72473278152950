import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, ModuleGuard } from '@evolenta/core';
import { LicensingActivityRegisterDefaultComponent } from './components/licensing-activity-register-default.component';
import { SidebarComponent } from '@components/sidebar/sidebar.component';

const routes: Routes = [
    {
        path: 'licensing-activity-register',
        component: SidebarComponent,
        data: {
            module: 'licensing-activity-register',
            breadcrumb: 'Лицензирование (общее)',
        },
        canActivate: [AuthGuard, ModuleGuard],
        canActivateChild: [AuthGuard, ModuleGuard],
        children: [
            {
                path: 'default',
                component: LicensingActivityRegisterDefaultComponent,
                data: {},
            },
            {
                path: '**',
                component: LicensingActivityRegisterDefaultComponent,
                data: {},
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
})
export class LicensingActivityRegisterRouting {
}
