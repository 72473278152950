import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, ModuleGuard, UniversalCollectionPageComponent } from '@evolenta/core';
import { SectionComponent } from '@components/section/section.component';
import { EmptyRoutingComponent } from '@components/empty-routing.component';
import { Permission } from '../../common/services/permission';
import { EventsSectionComponent } from '../../modules/events/events/events-section.component';
import { AppealsSectionComponent } from '../../modules/knm/appeals/appeals-section.component';
import { AppealsComponent } from '../../modules/knm/appeals/appeals.component';
import { AppealsResolve } from '../../modules/knm/appeals/appeals.resolve';
import { ServicesComponent } from '../../modules/subservices/services/services.component';
import { ServicesResolve } from '../../modules/subservices/services/services.resolve';
import { AppealEditComponent } from '../../modules/knm/appeals/appeal-edit.component';
import { TasksComponent } from '../../modules/tasks/tasks/tasks.component';
import { TasksResolve } from '../../modules/tasks/tasks/tasks.resolve';
import { TaskEditComponent } from '../../modules/tasks/tasks/task-edit.component';
import { EnvelopesResolve } from '../../modules/envelopes/envelopes/envelopes.resolve';
import { EnvelopeEditComponent } from '../../modules/envelopes/envelopes/envelope-edit.component';
import { OrganizationsResolve } from '../../modules/envelopes/envelopes/organizations.resolve';
import { HistoryComponent } from '@components/history/history.component';
import { EnvelopesListConfig } from '../../modules/envelopes/envelopes/envelopes-list.config';
import { AppealCreateComponent } from '../../modules/knm/appeals/appeal-create/appeal-create.component';
import { AppealProcessTasksComponent } from '../../modules/knm/appeals/components/appeal-process-tasks/appeal-process-tasks.component';
import { AppealSubservicesComponent } from '../../modules/knm/appeals/components/subservices/appeal-subservices.component';
import { AppealSubjectsComponent } from '../../modules/knm/appeals/components/appeal-subjects/appeal-subjects.component';
import { DocumentsComponent } from '../../modules/knm/appeals/components/documents/documents.component';
import { AppealRequirementsComponent } from '../../modules/knm/appeals/components/requirements/appeal-requirements.component';
import { SubjectsRoutingData } from '../../modules/subjects/subjects-routing.data';
import { ControlOperatorsSectionComponent } from '../../modules/control-operators/components/control-operators-section/control-operators-section.component';
import { ControlOperatorsComponent } from '../../modules/control-operators/components/control-operators/control-operators.component';
import { ControlOperatorsResolve } from '../../modules/control-operators/control-operators.resolve';
import { EnvelopeGuard } from '../../common/guards/envelope.guard';
import { NotificationsListConfig } from '../../modules/notifications/notifications/notifications-list.config';
import { NotificationsResolve } from '../../modules/notifications/notifications/notifications.resolve';
import { NotificationsPreviewComponent } from '../../modules/notifications/notifications/components/notifications-preview/notifications-preview.component';
import { CanDeactivateGuard } from '../../common/services/can-deactivate-guard.service';
import { AppealEditReloadComponent } from '../../modules/knm/appeals/appeal-edit-reload.component';
import { SidebarComponent } from '@components/sidebar/sidebar.component';

const routes: Routes = [
    {
        path: 'supervisor-register',
        component: SidebarComponent,
        data: {
            module: 'rsmev_register',
            breadcrumb: 'Кабинет специалиста',
        },
        canActivate: [AuthGuard, ModuleGuard],
        canActivateChild: [AuthGuard, ModuleGuard],
        children: [
            // ----------------- Запросы --------------- //
            {
                path: 'envelopes',
                component: SectionComponent,
                canActivateChild: [AuthGuard, ModuleGuard],
                data: {
                    name: 'Запросы',
                    breadcrumb: 'Запросы',
                    img: 'main-menu/requests',
                },
                children: [
                    {
                        path: '',
                        component: UniversalCollectionPageComponent,
                        data: {
                            name: 'Список запросов',
                            breadcrumb: 'Список запросов',
                            options: EnvelopesListConfig,
                        },
                        resolve: {
                            resolves: EnvelopesResolve,
                        },
                    },
                    {
                        path: 'create',
                        component: EnvelopeEditComponent,
                        data: {
                            name: 'Создание запроса',
                            breadcrumb: 'Создание запроса',
                        },
                        resolve: {
                            organizations: OrganizationsResolve,
                            resolves: EnvelopesResolve,
                        },
                    },
                    {
                        path: 'edit/:envelopeId',
                        component: EnvelopeEditComponent,
                        canActivate: [EnvelopeGuard],
                        data: {
                            name: 'Редактирование запроса',
                            breadcrumb: 'Редактирование запроса',
                        },
                        resolve: {
                            organizations: OrganizationsResolve,
                            resolves: EnvelopesResolve,
                        },
                    },
                ],
            },

            // ----------------- Дела --------------------- //
            {
                path: 'appeals',
                component: AppealsSectionComponent,
                canActivate: [AuthGuard],
                canActivateChild: [AuthGuard],
                data: {
                    name: 'Дела',
                    permissions: [Permission.Appeals_Search],
                    img: 'main-menu/processes',
                    breadcrumb: 'Дела',
                },
                children: [
                    {
                        path: '',
                        component: AppealsComponent,
                        data: {
                            name: 'Список дел',
                            permissions: [Permission.Appeals_Search],
                            version: 'rsmev_register',
                            breadcrumb: 'Список дел',
                        },
                        resolve: {
                            resolves: AppealsResolve,
                        },
                    },
                    {
                        path: 'services',
                        component: ServicesComponent,
                        data: {
                            name: 'Виды КНМ',
                            permissions: [Permission.Subservices_Search],
                            version: 'rsmev_register',
                            breadcrumb: 'Выбор услуги',
                        },
                        resolve: {
                            resolves: ServicesResolve,
                        },
                    },
                    {
                        path: 'create/:subserviceId',
                        component: AppealCreateComponent,
                        data: {
                            permissions: [
                                Permission.Appeal_Create,
                                Permission.Appeal_Status_Draft,
                            ],
                            allPermissionsRequired: true,
                            breadcrumb: 'Создание дела',
                        },
                        resolve: {
                            resolves: AppealsResolve,
                        },
                    },
                    {
                        path: 'create-complex/:complexSubserviceId',
                        component: AppealEditComponent,
                        data: {
                            permissions: [Permission.Appeal_Create],
                        },
                        resolve: {
                            resolves: AppealsResolve,
                        },
                    },
                    {
                        path: 'edit-reload/:appealId',
                        component: AppealEditReloadComponent,
                    },
                    {
                        path: 'edit/:appealId',
                        component: AppealEditComponent,
                        data: {
                            permissions: [
                                Permission.Appeal_Update,
                                Permission.Appeals_Search,
                            ],
                            breadcrumb: 'Редактирование дела',
                        },
                        resolve: {
                            resolves: AppealsResolve,
                        },
                        children: [
                            {
                                path: 'active-tasks',
                                component: AppealProcessTasksComponent,
                                data: {
                                    breadcrumb: 'Активные задачи',
                                },
                            },
                            {
                                path: 'completed-tasks',
                                component: AppealProcessTasksComponent,
                                data: {
                                    breadcrumb: 'Завершенные задачи',
                                },
                            },
                            {
                                path: 'subservices',
                                component: AppealSubservicesComponent,
                                data: {
                                    breadcrumb: 'Услуги',
                                },
                                canDeactivate: [CanDeactivateGuard],
                            },
                            {
                                path: 'subjects',
                                component: AppealSubjectsComponent,
                                data: {
                                    breadcrumb: 'Участники',
                                },
                                canDeactivate: [CanDeactivateGuard],
                            },
                            {
                                path: 'documents',
                                component: DocumentsComponent,
                                data: {
                                    breadcrumb: 'Документы',
                                },
                            },
                            {
                                path: 'history',
                                component: HistoryComponent,
                                data: {
                                    breadcrumb: 'История',
                                },
                            },
                            {
                                path: 'requirements',
                                component: AppealRequirementsComponent,
                                data: {},
                            },
                        ],
                    },
                ],
            },

            // ----------------- Задачи --------------------- //
            {
                path: 'tasks',
                component: EventsSectionComponent,
                canActivateChild: [AuthGuard],
                data: {
                    name: 'Мои задачи',
                    img: 'main-menu/tasks',
                    breadcrumb: 'Список задач',
                },
                children: [
                    {
                        path: '',
                        component: TasksComponent,
                        data: {
                            name: 'Мои задачи',
                            breadcrumb: 'Список задач',
                        },
                        resolve: {
                            resolves: TasksResolve,
                        },
                    },
                    {
                        path: 'edit/:mainId/:taskId',
                        component: TaskEditComponent,
                        data: {
                            breadcrumb: 'Настройка задачи',
                        },
                        resolve: {
                            resolves: TasksResolve,
                        },
                    },
                    {
                        path: 'create',
                        component: TaskEditComponent,
                        data: {
                            breadcrumb: 'Создание задачи',
                        },
                        resolve: {
                            resolves: TasksResolve,
                        },
                    },
                ],
            },
            {
                path: 'all-tasks',
                component: EventsSectionComponent,
                canActivate: [AuthGuard],
                canActivateChild: [AuthGuard],
                data: {
                    name: 'Все задачи',
                    img: 'main-menu/tasks',
                    active: false,
                    breadcrumb: 'Все задачи',
                },
                children: [
                    {
                        path: '',
                        component: TasksComponent,
                        data: {
                            name: 'Все задачи',
                            breadcrumb: 'Все задачи',
                        },
                        resolve: {
                            resolves: TasksResolve,
                        },
                    },
                    {
                        path: 'edit/:mainId/:taskId',
                        component: TaskEditComponent,
                        data: {
                            breadcrumb: 'Настройка задачи',
                        },
                        resolve: {
                            resolves: TasksResolve,
                        },
                    },
                    {
                        path: 'create',
                        component: TaskEditComponent,
                        data: {
                            breadcrumb: 'Создание задачи',
                        },
                        resolve: {
                            resolves: TasksResolve,
                        },
                    },
                ],
            },

            // ----------------- Участники --------------- //
            {
                path: 'subjects',
                component: SectionComponent,
                canActivateChild: [AuthGuard],
                data: {
                    name: 'Участники',
                    img: 'main-menu/subjects',
                    breadcrumb: 'Список участников',
                },
                children: [
                    {
                        path: 'global',
                        component: EmptyRoutingComponent,
                        data: {
                            breadcrumb: { skip: true },
                        },
                        children: SubjectsRoutingData.childRoutingWithEdit,
                    },
                    {
                        path: '',
                        redirectTo: 'global',
                        pathMatch: 'full',
                    },
                ],
            },
// ----------------- Уведомления --------------- //
            {
                path: 'notifications',
                component: SectionComponent,
                canActivateChild: [ AuthGuard, ModuleGuard ],
                data: {
                    name: 'Уведомления',
                    breadcrumb: 'Уведомления',
                    img: 'main-menu/messages',
                },
                children: [
                    {
                        path: '',
                        component: UniversalCollectionPageComponent,
                        data: {
                            name: 'Уведомления',
                            options: NotificationsListConfig,
                        },
                        resolve: {
                            resolves: NotificationsResolve,
                        },
                    },
                    {
                        path: ':notificationId',
                        component: NotificationsPreviewComponent,
                        resolve: {
                            resolves: NotificationsResolve,
                        },
                    },
                ],
            },
    // ----------------- Ответственные --------------- //

    {
        path: 'control-operators',
        component: ControlOperatorsSectionComponent,
        canActivateChild: [AuthGuard],
        data: {
            name: 'Ответственные',
            img: 'main-menu/user',
        },
        children: [
            {
                path: '',
                component: ControlOperatorsComponent,
                data: {
                    name: 'Все ответственные',
                },
                resolve: {
                    resolves: ControlOperatorsResolve,
                },
            },
        ],
    },
            {
                path: '',
                data: {},
                redirectTo: 'appeals',
                pathMatch: 'full',
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
})
export class SupervisorRegisterRouting {
}
