import { NgModule } from '@angular/core';
import { LeafletMapModalComponent } from '@components/modal/leaflet-map-modal/leaflet-map-modal.component';
import { InlineSVGModule } from 'ng-inline-svg';
import { AppealEnvelopesService } from './appeals/components/envelopes/appeal-envelopes.service';
import { AppealSaveService } from './appeals/appeal-save.service';
import { AppealService } from './appeals/appeal.service';
import { AppealsResolve } from './appeals/appeals.resolve';
import { AppealValidateService } from './appeals/appeal-validate.service';
import { AppealSubservicesService } from './appeals/appeal-subservices.service';
import { AppealStatusService } from './appeals/appeal-status.service';
import { DocumentService } from './appeals/components/documents/document.service';
import { ObjectsService } from './appeals/components/objects/objects.service';
import { AppealRequirementsService } from './appeals/appeal-requirements.service';
import { EventsResolve } from '../events/events/events.resolve';
import { AppealProcessService } from './appeals/components/process/appeal-process.service';
import { AppealEntitiesService } from './appeals/components/entities/appeal-entities.service';
import { AppealAdditionalDataComponent } from './appeals/components/additional-data/appeal-additional-data.component';
import { AppealCommonComponent } from './appeals/components/appeal-common/appeal-common.component';
import { AppealDocumentDocumentsComponent } from './appeals/components/documents/components/document-documents/appeal-document-documents.component';
import { AppealDocumentAmountComponent } from './appeals/components/documents/components/document-amount/appeal-document-amount.component';
import { AppealDocumentCardComponent } from './appeals/components/documents/components/document-card/appeal-document-card.component';
import { AppealDocumentEnvelopeComponent } from './appeals/components/documents/components/document-envelope/appeal-document-envelope.component';
import { AppealDocumentFilesComponent } from './appeals/components/documents/components/document-files/appeal-document-files.component';
import { AppealDocumentParticipantsComponent } from './appeals/components/documents/components/document-participants/appeal-document-participants.component';
import { AppealDocumentPreviewComponent } from './appeals/components/documents/components/document-preview/appeal-document-preview.component';
import { AppealEnvelopeComponent } from './appeals/components/envelopes/appeal-envelope.component';
import { AppealDocumentPropertiesComponent } from './appeals/components/documents/components/document-properties/appeal-document-properties.component';
import { AppealDocumentXsdComponent } from './appeals/components/documents/components/document-xsd/appeal-document-xsd.component';
import { AppealDocumentCommonComponent } from './appeals/components/documents/components/document-common/appeal-document-common.component';
import { AppealEditComponent } from './appeals/appeal-edit.component';
import { AppealEnvelopesComponent } from './appeals/components/envelopes/appeal-envelopes.component';
import { AppealMessageComponent } from './appeals/components/messages/appeal-message.component';
import { AppealMessagesComponent } from './appeals/components/messages/appeal-messages.component';
import { AppealObjectAdditionalDataComponent } from './appeals/components/objects/object-additional-data/appeal-object-additional-data.component';
import { AppealSubserviceCardComponent } from './appeals/components/subservices/components/subservice-card/appeal-subservice-card.component';
import { AppealSubserviceAdditionalDataComponent } from './appeals/components/subservices/components/subservice-additional-data/appeal-subservice-additional-data.component';
import { AppealsComponent } from './appeals/appeals.component';
import { AppealSubserviceDocumentGroupsComponent } from './appeals/components/documents/components/document-groups/appeal-subservice-document-groups.component';
import { DocumentObjectsFilterPipe } from './appeals/components/documents/document-objects-filter.pipe';
import { AppealSubserviceOrganizationComponent } from './appeals/components/subservices/components/subservice-organization/appeal-subservice-organization.component';
import { AppealSubservicePreviewComponent } from './appeals/components/subservices/components/subservice-preview/appeal-subservice-preview.component';
import { AppealSubservicesComponent } from './appeals/components/subservices/appeal-subservices.component';
import { AppealSubserviceVariantComponent } from './appeals/components/subservices/components/subservice-variant/appeal-subservice-variant.component';
import { DocumentsComponent } from './appeals/components/documents/documents.component';
import { DocumentsFilterPipe } from './appeals/components/documents/documents-filter.pipe';
import { ObjectsByGroupFilterPipe } from './appeals/components/objects/objects-by-group-filter.pipe';
import { ObjectsByMessageTypeFilterPipe } from './appeals/components/messages/objects-by-message-type-filter.pipe';
import { ObjectsByRepresentativeFilterPipe } from './appeals/components/objects/objects-by-representative-filter.pipe';
import { ObjectsComponent } from './appeals/components/objects/objects.component';
import { AppealsSectionComponent } from './appeals/appeals-section.component';
import { AppealRequirementsComponent } from './appeals/components/requirements/appeal-requirements.component';
import { AppealEntityRaidWarningComponent } from './appeals/components/entities/components/entity-raid-warning/appeal-entity-raid-warning.component';
import { AppealEntityRaidTaskComponent } from './appeals/components/entities/components/entity-raid-task/appeal-entity-raid-task.component';
import { AppealEntityRaidAnswerComponent } from './appeals/components/entities/components/entity-raid-answer/appeal-entity-raid-answer.component';
import { AppealEntityRaidActComponent } from './appeals/components/entities/components/entity-raid-act/appeal-entity-raid-act.component';
import { AppealEntityProceedTerminationComponent } from './appeals/components/entities/components/entity-proceed-termination/appeal-entity-proceed-termination.component';
import { AppealEntityProceedReferralComponent } from './appeals/components/entities/components/entity-proceed-referral/appeal-entity-proceed-referral.component';
import { AppealEntityProceedProtocolReviewComponent } from './appeals/components/entities/components/entity-proceed-protocol-review/appeal-entity-proceed-protocol-review.component';
import { AppealEntityProceedProtocolMakeupComponent } from './appeals/components/entities/components/entity-proceed-protocol-makeup/appeal-entity-proceed-protocol-makeup.component';
import { AppealEntityProceedProtocolComponent } from './appeals/components/entities/components/entity-proceed-protocol/appeal-entity-proceed-protocol.component';
import { AppealEntityProceedInitiationComponent } from './appeals/components/entities/components/entity-proceed-initiation/appeal-entity-proceed-initiation.component';
import { AppealEntityProceedDecreeComponent } from './appeals/components/entities/components/entity-proceed-decree/appeal-entity-proceed-decree.component';
import { AppealEntityProceedInformationComponent } from './appeals/components/entities/components/entity-proceed-information/appeal-entity-proceed-information.component';
import { AppealEntityProceedConductingComponent } from './appeals/components/entities/components/entity-proceed-conducting/appeal-entity-proceed-conducting.component';
import { AppealEntityProceedActionComponent } from './appeals/components/entities/components/entity-proceed-action/appeal-entity-proceed-action.component';
import { AppealEntityObservationWarningComponent } from './appeals/components/entities/components/entity-observation-warning/appeal-entity-observation-warning.component';
import { AppealEntityObservationRequestComponent } from './appeals/components/entities/components/entity-observation-request/appeal-entity-observation-request.component';
import { AppealEntityObservationPrescriptionComponent } from './appeals/components/entities/components/entity-observation-prescription/appeal-entity-observation-prescription.component';
import { AppealEntityObservationNotifyComponent } from './appeals/components/entities/components/entity-observation-notify/appeal-entity-observation-notify.component';
import { AppealEntityObservationDocumentComponent } from './appeals/components/entities/components/entity-observation-document/appeal-entity-observation-document.component';
import { AppealEntityObservationAnswerComponent } from './appeals/components/entities/components/entity-observation-answer/appeal-entity-observation-answer.component';
import { AppealEntityObservationActComponent } from './appeals/components/entities/components/entity-observation-act/appeal-entity-observation-act.component';
import { AppealEntityBuyOrderComponent } from './appeals/components/entities/components/entity-buy-order/appeal-entity-buy-order.component';
import { AppealEntityBuyNoticeComponent } from './appeals/components/entities/components/entity-buy-notice/appeal-entity-buy-notice.component';
import { AppealEntityBuyActComponent } from './appeals/components/entities/components/entity-buy-act/appeal-entity-buy-act.component';
import { AppealEntityRequirementExplanationComponent } from './appeals/components/entities/components/entity-requirement-explanation/appeal-entity-requirement-explanation.component';
import { AppealEntityRequestMotivatedComponent } from './appeals/components/entities/components/entity-request-motivated/appeal-entity-request-motivated.component';
import { AppealEntityActNotImpossibleComponent } from './appeals/components/entities/components/entity-act-not-impossible/appeal-entity-act-not-impossible.component';
import { AppealRequirementCardComponent } from './appeals/components/requirements/components/requirement-card/appeal-requirement-card.component';
import { AppealSubjectObjectsComponent } from './appeals/components/objects/subject-objects/appeal-subject-objects.component';
import { AppealSubjectObjectCardComponent } from './appeals/components/objects/subject-objects/appeal-subject-object-card.component';
import { ObjectModalComponent } from './appeals/components/objects/special-types/object-modal/object-modal.component';
import { AppealProcessTaskCardComponent } from './appeals/components/process/components/task-card/appeal-process-task-card.component';
import { AppealProcessComponent } from './appeals/components/process/appeal-process.component';
import { AppealProcessTaskPreviewComponent } from './appeals/components/appeal-process-task/components/task-preview/appeal-process-task-preview.component';
import { AppealEntitiesComponent } from './appeals/components/entities/appeal-entities.component';
import { AppealEntityOrderComponent } from './appeals/components/entities/components/entity-order/appeal-entity-order.component';
import { AppealEntityRegulationComponent } from './appeals/components/entities/components/entity-regulation/appeal-entity-regulation.component';
import { AppealEntityCardComponent } from './appeals/components/entities/components/entity-card/appeal-entity-card.component';
import { AppealEntityActComponent } from './appeals/components/entities/components/entity-act/appeal-entity-act.component';
import { AppealEntitySubjectsComponent } from './appeals/components/entities/components/components/entity-subjects/appeal-entity-subjects.component';
import { AppealSubserviceCommonComponent } from './appeals/components/subservices/components/subservice-common/appeal-subservice-common.component';
import { AppealEntityDictionaryComponent } from './appeals/components/entities/components/components/entity-dictionary/appeal-entity-dictionary.component';
import { AppealProcessTaskComponent } from './appeals/components/appeal-process-task/appeal-process-task.component';
import { AppealDocumentGroupComponent } from './appeals/components/documents/components/document-group/appeal-document-group.component';
import { AppealProcessTasksComponent } from './appeals/components/appeal-process-tasks/appeal-process-tasks.component';
import { AppealEntityDocumentsComponent } from './appeals/components/entities/components/components/entity-documents/appeal-entity-documents.component';
import { AppealEntityNotificationComponent } from './appeals/components/entities/components/entity-notification/appeal-entity-notification.component';
import { ReasonModalComponent } from './appeals/components/objects/special-types/reason-modal/reason-modal.component';
import { ReestrModalComponent } from './appeals/components/objects/special-types/reestr-modal/reestr-modal.component';
import { SharedComponentsModule } from '../../common/shared-components.module';
import { SharedElementsModule } from '../../common/shared-elements.module';
import { EnvelopesModule } from '../envelopes/envelopes.module';
import { SubjectsModule } from '../subjects/subjects.module';
import { AppealPreviewComponent } from './appeals/components/appeal-preview/appeal-preview.component';
import { PrintFormsModule } from '../print-forms/print-forms.module';
import { ObjectsModule } from '../objects/objects.module';
import { AppealEntityViolationDecisionComponent } from './appeals/components/entities/components/entity-violation-decision/appeal-entity-violation-decision.component';
import { AppealEntityViolationMeasureComponent } from './appeals/components/entities/components/entity-violation-measure/appeal-entity-violation-measure.component';
import { AppealData } from './appeals/appeal.data';
import { AppealEntityExpertComponent } from './appeals/components/entities/components/components/entity-expert/appeal-entity-expert.component';
import { AppealEntityEmployeeComponent } from './appeals/components/entities/components/components/entity-employee/appeal-entity-employee.component';
import { AppealEntityFieldInputComponent } from './appeals/components/entities/components/components/entity-field-input/appeal-entity-field-input.component';
import { AppealEntityCatalogueComponent } from './appeals/components/entities/components/components/entity-catalogue/appeal-entity-catalogue.component';
import { AppealEntityPersonsComponent } from './appeals/components/entities/components/components/entity-persons/appeal-entity-persons.component';
import { AppealEntityViolationsComponent } from './appeals/components/entities/components/components/entity-violations/appeal-entity-violations.component';
import { AppealEntityEventsComponent } from './appeals/components/entities/components/components/entity-events/appeal-entity-events.component';
import { AppealEntityPrescriptionsComponent } from './appeals/components/entities/components/components/entity-prescriptions/appeal-entity-prescriptions.component';
import { AppealEntityCharacterComponent } from './appeals/components/entities/components/components/entity-character/appeal-entity-character.component';
import { AppealEntityBuyActDistanceComponent } from './appeals/components/entities/components/entity-buy-act-distance/appeal-entity-buy-act-distance.component';
import { AppealEntityDamageComponent } from './appeals/components/entities/components/entity-damage/appeal-entity-damage.component';
import { AppealEntityCheckStatementComponent } from './appeals/components/entities/components/entity-check-statement/appeal-entity-check-statement.component';
import { AppealEntityCheckExaminationComponent } from './appeals/components/entities/components/entity-check-examination/appeal-entity-check-examination.component';
import { AppealEntityCheckPlaceComponent } from './appeals/components/entities/components/components/entity-check-place/appeal-entity-check-place.component';
import { AppealEntityNsiViolationsComponent } from './appeals/components/entities/components/entity-nsi-violations/appeal-entity-nsi-violations.component';
import { AppealEntityRiskEventComponent } from './appeals/components/entities/components/entity-risk-event/appeal-entity-risk-event.component';
import { AppealCardComponent } from './appeals/appeal-card/appeal-card.component';
import { AppealEntityViolationActionsComponent } from './appeals/components/entities/components/components/entity-violation-actions/appeal-entity-violation-actions.component';
import { AppealEntityPerscriptionItemsComponent } from './appeals/components/entities/components/components/entity-prescription-items/appeal-entity-perscription-items.component';
import { AppealEntityViolationDeadlineComponent } from './appeals/components/entities/components/components/entity-violation-deadline/appeal-entity-violation-deadline.component';
import { AppealEntityRequirementsComponent } from './appeals/components/entities/components/components/entity-requirements/appeal-entity-requirements.component';
import { AppealEntityViolationStatisticComponent } from './appeals/components/entities/components/components/entity-violation-statistics/appeal-entity-violation-statistic.component';
import { AppealEntityIncomingDocumentComponent } from './appeals/components/entities/components/entity-incoming-document/appeal-entity-incoming-document.component';
import { AppealSubjectCardComponent } from './appeals/components/appeal-subjects/appeal-subject-card/appeal-subject-card.component';
import { AppealSubjectsComponent } from './appeals/components/appeal-subjects/appeal-subjects.component';
import { AppealObjectsComponent } from './appeals/components/appeal-objects/appeal-objects.component';
import { AppealObjectCardComponent } from './appeals/components/appeal-objects/appeal-object-card/appeal-object-card.component';
import { AppealSubjectSubservicesComponent } from './appeals/components/appeal-subjects/appeal-subject-subservices/appeal-subject-subservices.component';
import { AppealObjectSubservicesComponent } from './appeals/components/appeal-objects/appeal-object-subservices/appeal-object-subservices.component';
import { AppealEntityDocumentsFieldComponent } from './appeals/components/entities/components/components/entity-documents-field/entity-documents-field.component';
import { AppealEntityConformityConclusionComponent } from './appeals/components/entities/components/entity-conformity-conclusion/appeal-entity-conformity-conclusion.component';
import { AppealEntityRejectConformityConclusionComponent } from './appeals/components/entities/components/entity-reject-conformity-conclusion/appeal-entity-reject-conformity-conclusion.component';
import { AppealSubserviceSubjectsComponent } from './appeals/components/subservices/components/subservice-subjects/appeal-subservice-subjects.component';
import { AppealCreateComponent } from './appeals/appeal-create/appeal-create.component';
import { AppealSubservicesCreateComponent } from './appeals/components/subservices/create/appeal-subservices-create.component';
import { DocumentsCreateComponent } from './appeals/components/documents/create/documents-create.component';
import { AppealSubjectsCreateComponent } from './appeals/components/appeal-subjects/create/appeal-subjects-create.component';
import { AppealProcessTaskXsdDataComponent } from './appeals/components/appeal-process-task/components/task-xsd-data/task-xsd-data/appeal-process-task-xsd-data.component';
import { BreadcrumbsModule } from '@evolenta/breadcrumbs';
import { AppealEditReloadComponent } from './appeals/appeal-edit-reload.component';
import { ErrorLoggingService } from './error-logging.service';

@NgModule({
    imports: [
        SharedComponentsModule,
        SharedElementsModule,
        EnvelopesModule,
        SubjectsModule,
        ObjectsModule,
        PrintFormsModule,
        InlineSVGModule,
        BreadcrumbsModule,
    ],
    declarations: [
        AppealAdditionalDataComponent,
        AppealCommonComponent,
        AppealDocumentAmountComponent,
        AppealDocumentCardComponent,
        AppealDocumentDocumentsComponent,
        AppealDocumentEnvelopeComponent,
        AppealDocumentFilesComponent,
        AppealDocumentParticipantsComponent,
        AppealDocumentPreviewComponent,
        AppealDocumentPropertiesComponent,
        AppealDocumentXsdComponent,
        AppealDocumentCommonComponent,
        AppealCreateComponent,
        AppealEditComponent,
        AppealEditReloadComponent,
        AppealEnvelopeComponent,
        AppealEnvelopesComponent,
        AppealMessageComponent,
        AppealMessagesComponent,
        AppealObjectAdditionalDataComponent,
        AppealObjectCardComponent,
        AppealsComponent,
        AppealSubserviceAdditionalDataComponent,
        AppealSubserviceCardComponent,
        AppealSubserviceDocumentGroupsComponent,
        AppealSubserviceSubjectsComponent,
        AppealSubserviceOrganizationComponent,
        AppealSubservicePreviewComponent,
        AppealSubservicesCreateComponent,
        AppealSubservicesComponent,
        AppealSubserviceVariantComponent,
        DocumentObjectsFilterPipe,
        DocumentsCreateComponent,
        DocumentsComponent,
        DocumentsFilterPipe,
        ObjectsByGroupFilterPipe,
        ObjectsByMessageTypeFilterPipe,
        ObjectsByRepresentativeFilterPipe,
        ObjectsComponent,
        AppealsSectionComponent,
        AppealRequirementsComponent,
        AppealRequirementCardComponent,
        AppealSubjectObjectsComponent,
        AppealSubjectObjectCardComponent,
        AppealSubjectObjectCardComponent,
        AppealSubjectObjectCardComponent,
        ObjectModalComponent,
        AppealSubjectObjectCardComponent,
        AppealProcessTaskCardComponent,
        AppealProcessComponent,
        AppealProcessTaskPreviewComponent,
        AppealEntitiesComponent,
        AppealEntityCardComponent,
        AppealEntityRegulationComponent,
        AppealEntityActComponent,
        AppealEntityOrderComponent,
        AppealEntitySubjectsComponent,
        AppealSubserviceCommonComponent,
        AppealEntityDictionaryComponent,
        AppealProcessTaskComponent,
        AppealDocumentGroupComponent,
        AppealProcessTasksComponent,
        AppealEntityDocumentsComponent,
        AppealEntityNotificationComponent,
        AppealEntityActNotImpossibleComponent,
        AppealEntityRequestMotivatedComponent,
        AppealEntityRequirementExplanationComponent,
        AppealEntityBuyActComponent,
        AppealEntityBuyActDistanceComponent,
        AppealEntityBuyNoticeComponent,
        AppealEntityBuyOrderComponent,
        AppealEntityObservationActComponent,
        AppealEntityObservationAnswerComponent,
        AppealEntityObservationDocumentComponent,
        AppealEntityObservationNotifyComponent,
        AppealEntityObservationPrescriptionComponent,
        AppealEntityObservationRequestComponent,
        AppealEntityObservationWarningComponent,
        AppealEntityProceedActionComponent,
        AppealEntityProceedConductingComponent,
        AppealEntityProceedInformationComponent,
        AppealEntityProceedDecreeComponent,
        AppealEntityProceedInitiationComponent,
        AppealEntityProceedProtocolComponent,
        AppealEntityProceedProtocolMakeupComponent,
        AppealEntityProceedProtocolReviewComponent,
        AppealEntityProceedReferralComponent,
        AppealEntityProceedTerminationComponent,
        AppealEntityRaidActComponent,
        AppealEntityRaidAnswerComponent,
        AppealEntityRaidTaskComponent,
        AppealEntityRaidWarningComponent,
        AppealEntityViolationDecisionComponent,
        AppealEntityViolationMeasureComponent,
        AppealEntityCheckStatementComponent,
        AppealEntityCheckExaminationComponent,
        AppealEntityDamageComponent,
        AppealEntityNsiViolationsComponent,
        AppealEntityRiskEventComponent,
        ReasonModalComponent,
        ReestrModalComponent,
        AppealPreviewComponent,
        AppealEntityExpertComponent,
        AppealEntityEmployeeComponent,
        AppealEntityPersonsComponent,
        AppealEntityFieldInputComponent,
        AppealEntityCatalogueComponent,
        AppealEntityViolationsComponent,
        AppealEntityEventsComponent,
        AppealEntityPrescriptionsComponent,
        AppealEntityCharacterComponent,
        AppealPreviewComponent,
        AppealEntityCheckPlaceComponent,
        AppealEntityViolationActionsComponent,
        AppealEntityViolationDeadlineComponent,
        AppealCardComponent,
        AppealEntityPerscriptionItemsComponent,
        AppealEntityRequirementsComponent,
        AppealEntityViolationStatisticComponent,
        AppealEntityIncomingDocumentComponent,
        AppealSubjectCardComponent,
        AppealSubjectsComponent,
        AppealSubjectsCreateComponent,
        AppealObjectsComponent,
        AppealObjectCardComponent,
        AppealSubjectSubservicesComponent,
        AppealObjectSubservicesComponent,
        AppealEntityDocumentsFieldComponent,
        AppealEntityConformityConclusionComponent,
        AppealEntityRejectConformityConclusionComponent,
        AppealProcessTaskXsdDataComponent,
    ],
    entryComponents: [
        LeafletMapModalComponent,
        AppealEntityPerscriptionItemsComponent,
    ],
    providers: [
        AppealEnvelopesService,
        AppealSaveService,
        AppealService,
        AppealsResolve,
        AppealStatusService,
        AppealSubservicesService,
        AppealValidateService,
        DocumentService,
        ObjectsService,
        AppealRequirementsService,
        EventsResolve,
        AppealProcessService,
        AppealProcessService,
        AppealEntitiesService,
        AppealData,
        ErrorLoggingService,
    ],
    exports: [
        AppealPreviewComponent,
        AppealCardComponent,
    ],
})
export class KnmModule {
}
