import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AccessService, TranslateService } from '@evolenta/core';
import { AppealValidateService } from '../../../../appeal-validate.service';
import { DocumentService } from '../../document.service';
import { Permission } from '../../../../../../../common/services/permission';
import { EnvelopesService } from '../../../../../../../common/services/envelopes.service';
import { AppealDocumentEnvelopeComponent } from '../document-envelope/appeal-document-envelope.component';

@Component({
    selector: 'appeal-document-common',
    templateUrl: 'appeal-document-common.component.html',
})
export class AppealDocumentCommonComponent implements OnInit {
    @Input() public document; // документ дела
    @Input() public group; // группа документадокумент дела
    @Input() public appeal; // обрабатываемое дело

    @Output() public afterSendEnvelope = new EventEmitter<object>();
    @Output() public afterSaveEnvelope = new EventEmitter<object>();

    public data; // описательный объект для документа
    public isProcessSelectOrganization = false;

    public permissions = Permission;
    public localizations;
    @ViewChild('documentEnvelope', { static: false }) public documentEnvelopeComponent: AppealDocumentEnvelopeComponent;

    public constructor(
        public validateService: AppealValidateService,
        public accessService: AccessService,
        private documentService: DocumentService,
        private envelopeService: EnvelopesService,
        private translate: TranslateService,
    ) {
    }

    /**
     * Инициализация компонента
     */
    public ngOnInit() {
        this._loadTranslations();
        this.data = this.documentService.data[this.document.guid];
    }

    public _loadTranslations() {
        this.translate.get(
            [
                'common',
                'appeals.documents',
            ],
        ).subscribe((res: any) => {
            this.localizations = res;
        });
    }

    /**
     * КНМ необходимости выбора участника для документа
     * @returns {boolean | any}
     */
    public get visibleParticipantsBlock() {
        if (this.document.requestId) {
            return this.data.envelope && this.data.envelope.request && !this.data.envelope.request.disableParticipants && (this.data.allowEdit || this.data.allowPartiallyEdit);
        } else {
            return this.group && (this.group.appealRequirements && this.group.appealRequirements.bySubjects || !this.group.guid) && this.data.allowEdit
                   && !this.data.link && !this.data.resultSubserviceLink;
        }
    }

    public get visibleObjectsBlock() {
        if (!this.document.requestId) {
            return this.group && (this.group.appealRequirements && this.group.appealRequirements.byObjects || !this.group.guid) && this.data.allowEdit
                && !this.data.link && !this.data.resultSubserviceLink;
        }
    }

    /**
     * КНМ необходимости вывода блока с добавлением документов дела
     */
    public get visibleDocumentsBlock() {
        return this.data.envelope && this.data.envelope.request && !this.data.envelope.request.disableOutputDocs;
    }

    /**
     * Получение параметров для документа-запроса
     * @param property - возвращаемое значение свойства
     * @returns {string}
     */
    public getEnvelopeStatusProperty(property) {
        const envelopeStatus = this.data.envelope.status ? this.data.envelope.status : 'created';

        return EnvelopesService.GetStatusProperty(envelopeStatus, property);
    }

    public processSendEnvelope() {
        // Вызов метода отправки запроса из соответствующего компонента для документа
        return this.documentEnvelopeComponent.processSendEnvelope();
    }

    public saveEnvelope() {
        this.documentEnvelopeComponent.saveEnvelope();
    }

    public selectOrganization(org) {}

    /**
     * Сохранение XSD-данных запроса в документ и приме
     */
    public onAfterSaveEnvelope() {
        this.afterSaveEnvelope.emit(this.document);
    }

    public onAfterSendEnvelope() {
        this.afterSendEnvelope.emit(this.document);
    }

}
