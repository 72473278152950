import { NgModule } from '@angular/core';
import { SupervisorRegisterRouting } from './supervisor-register.routing';
import { SharedComponentsModule } from '../../common/shared-components.module';
import { EventsModule } from '../../modules/events/events.module';
import { KnmModule } from '../../modules/knm/knm.module';
import { TasksModule } from '../../modules/tasks/tasks.module';
import { SubservicesModule } from '../../modules/subservices/subservices.module';
import { DashboardModule } from '../../modules/dashboard/dashboard.module';
import { StandardsModule } from '../../modules/standards/standards.module';
import { ControlOperatorsModule } from '../../modules/control-operators/control-operators.module';

@NgModule({
    imports: [
        SupervisorRegisterRouting,
        SharedComponentsModule,
        DashboardModule,
        EventsModule,
        KnmModule,
        TasksModule,
        SubservicesModule,
        StandardsModule,
        ControlOperatorsModule,
    ],
})
export class SupervisorRegisterModule {
}
