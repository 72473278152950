import { NgModule } from '@angular/core';
import { LicensingActivityRegisterRouting } from './licensing-activity-register-routing.module';
import { SharedComponentsModule } from '../../common/shared-components.module';
import { SharedElementsModule } from '../../common/shared-elements.module';
import { SubservicesModule } from '../../modules/subservices/subservices.module';
import { RegistersModule } from '../../modules/registers/registers.module';
import { CommonAppealsModule } from '../../modules/common-appeals/common-appeals.module';
import { NotificationsModule } from '../../modules/notifications/notifications.module';
import { LicensingActivityRegisterDefaultComponent } from './components/licensing-activity-register-default.component';

@NgModule({
    imports: [
        LicensingActivityRegisterRouting,
        SharedComponentsModule,
        SharedElementsModule,
        CommonAppealsModule,
        SubservicesModule,
        RegistersModule,
        NotificationsModule,
    ],
    declarations: [
        LicensingActivityRegisterDefaultComponent,
    ],
})
export class LicensingActivityRegisterModule {
}
