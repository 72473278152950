import { Injectable } from '@angular/core';
// @ts-ignore
import { RestService, SessionService, StorageService, ToasterService } from '@evolenta/core';
import { Router } from '@angular/router';
import { PersonService } from '../../../common/services/persons.service';
import { Config } from '../../../common/services/config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CommonAppealSubservicesService } from './common-appeal-subservices.service';
import { CommonAppealDocumentService } from '../components/appeal-documents/common-appeal-document.service';
import cloneDeep from 'lodash-es/cloneDeep';
import isEqual from 'lodash-es/isEqual';
import merge from 'lodash-es/merge';
import clone from 'lodash-es/clone';
import { ErrorLoggingService } from '../../knm/error-logging.service';
import * as _ from 'lodash-es';

@Injectable()
export class CommonAppealSaveService {
    public appeal; // обрабатываемое дело
    public baseAppeal; // предыдущая актуальная на сервере версия дела
    public documentsWithFiles = {}; // Объект, необходимый для КНМ статуса загрузки файлов документов
    public reestrModal; // модальное окно для выбора соответствия объекта дела и клиента в реестре клиентов
    public isProcessSavingAppeal = false; // Флаг осуществления процесса сохранения дела в данный момент времени
    public isCreateAppealMode = false; // Режим создания дела

    public moduleBaseUrl = 'ais';
    public baseUrl = 'appeals';
    public saveFromRequirements = false; // без этого при повторном заходе количесвто листов увеличивается

    public metaReglament;
    public mainCollection;

    public constructor(
        private http: HttpClient,
        private restService: RestService,
        private session: SessionService,
        private router: Router,
        private toaster: ToasterService,
        private documentService: CommonAppealDocumentService,
        private appealSubservicesService: CommonAppealSubservicesService,
        private errorLoggingService: ErrorLoggingService,
        private personService: PersonService,
        private storageService: StorageService,
    ) {}

    public clearData() {
        this.metaReglament = null;
        this.appeal = null;
        this.mainCollection = null;
    }

    /**
     * Базовая функция сохранения дела: КНМ на изменения в основных сущностях дела + в объектах и документах - запуск дочерней процедуры сохранения
     */
    public saveAppeal(component = null, params = null) {
        if (params && params.isNotSave) {
            return;
        }
        this.isProcessSavingAppeal = true;

        this.mainCollection = this.appeal.subservice.appealsCollection
            ? this.appeal.subservice.appealsCollection
            : 'appeals';
        this.isProcessSavingAppeal = true;

        // Инициализация базового дела
        if (!this.baseAppeal) {
            this.baseAppeal = {};
        }

        if (component === 'requirements') {
            this.saveFromRequirements = true;
        }
        // Сравнение основных полей дела для формирования объекта на сохранение
        const appealForSave: any = {}; // объект дела, включающий только измененный состав полей
        // Пробегаемся по полям дела для сравнения с аналогичными полями базового дела
        Object.keys(this.appeal).forEach(item => {
            // Если поле не самостоятельные сущности: объекты, документы, услуги (они сохраняются отдельными сущностями)
            if (item !== 'objects' && item !== 'documents' && item !== 'subservice' && item !== 'subjects') {
                // Сравнение остальных полей дела
                if (JSON.stringify(this.appeal[item]) !== JSON.stringify(this.baseAppeal[item])) {
                    appealForSave[item] = this.appeal[item];
                }
            }
        });

        delete appealForSave.erpEnvelopesInfo;
        delete appealForSave.tmpDocs; // todo: check if tmpDocs exists here

        // Если есть изменения в основном наборе полей, сохраняем сначала основную сущность дела
        if (Object.keys(appealForSave).length) {
            if (!this.appeal._id) {
                // Создание дела на сервере
                return this.restService.create(this.mainCollection, appealForSave).then(
                    (createdAppeal: any) => {
                        this.isCreateAppealMode = true;
                        // Удаляем отдельные сущности пришедшие с сохраненным делом с сервера
                        delete createdAppeal.objects;
                        delete createdAppeal.documents;
                        delete createdAppeal.subservices;
                        delete createdAppeal.subjects;
                        // Объединяем поля измененного дела с текущим значением
                        this.appeal = merge(this.appeal, createdAppeal);
                        // this.appeal.created = true; // флаг необходимости перезагрузки страницы после создания дела

                        // Обновление данных дела в базовом объекте дела
                        this.baseAppeal = merge(this.baseAppeal, createdAppeal);

                        // Инициализация сохранения услуг дела
                        return this.saveAppealSubservice();
                    },
                    error => {
                        // Ошибка сохранения, обрыв процедуры сохранения дела
                        this.isProcessSavingAppeal = false;

                        return Promise.reject(error);
                    },
                );
            } else {
                // Обновление дела на сервере
                // Добавление в сохраняемых объект ID и GUID дела
                appealForSave._id = this.appeal._id;
                appealForSave.guid = this.appeal.guid;

                return this.restService.update(this.mainCollection, appealForSave).then(
                    (updateAppeal: any) => {
                        // Удаляем отдельные сущности пришедшие с сохраненным делом с сервера
                        delete updateAppeal.objects;
                        delete updateAppeal.documents;
                        delete updateAppeal.subservices;
                        delete updateAppeal.subjects;
                        // Объединяем поля измененного дела с текущим значением
                        this.appeal = merge(this.appeal, updateAppeal);

                        // Обновление данных дела в базовом объекте дела
                        this.baseAppeal = merge(this.baseAppeal, updateAppeal);

                        // Инициализация сохранения услуг дела

                        return this.saveAppealSubservice();
                    },
                    error => {
                        this.isProcessSavingAppeal = false;

                        return Promise.reject(error);
                    },
                );
            }
        } else {
            // Основной состав дела не изменился, сохраняем услуги дела
            return this.saveAppealSubservice();
        }
    }

    /**
     * Сохранение услуг в деле
     */
    public saveAppealSubservice() {
        // Получение набора промисов для измененных (только измененный состав полей) или добавленных объектов
        const promises = this._processAppealEntities(this.appeal, 'subservice');

        return Promise.all(
            promises.map(saveResults =>
                saveResults.then(
                    success => ({ success }),
                    error => ({ error }),
                ),
            ),
        ).then(subservices => {
            let error = null; // ошибка при сохранении услуг дела
            subservices.forEach(subserviceData => {
                // Если было не удаление, а добавление или обновление услуги
                if (subserviceData.success) {
                    // Сохранение прошло успешно
                    const subservice = subserviceData.success;
                    if (subservice && this.appeal.subservice.guid === subservice.guid) {
                        // Если была процедура создания или редактирования, а не удаления
                        this.appeal.subservice = merge(this.appeal.subservice, subservice);
                        let baseAppeal = this.storageService.getItem('baseAppeal');
                        baseAppeal = baseAppeal || cloneDeep(this.appeal);
                        baseAppeal.subservice = subservice;
                        this.storageService.setItem('baseAppeal', baseAppeal);
                    }
                } else {
                    // Ошибка при сохранении
                    error = subserviceData.error;
                }
            });
            if (!error) {
                // Инициализация сохранения объектов дела
                return this.saveAppealSubjects();
            } else {
                // Ошибка, прерываем процедуру сохранения
                this.isProcessSavingAppeal = false;

                return Promise.reject(error);
            }
        });
    }

    /**
     * Окончательное сохранение объектов и переход к сохранению объектов
     */
    private saveAppealSubjects() {
        // Получение набора промисов для измененных (только измененный состав полей) или добавленных объектов
        const promises = this._processAppealEntities(this.appeal, 'subjects');

        return Promise.all(
            promises.map(saveResults =>
                saveResults.then(
                    success => ({ success }),
                    error => ({ error }),
                ),
            ),
        ).then(subjects => {
            let error = null;
            subjects.forEach(subjectData => {
                if (subjectData.success) {
                    // Данные объекта успешно сохранены, обновляем информацию в деле
                    const subject = subjectData.success;
                    if (subject) {
                        delete subject.fieldRequirements;
                        // Если было осуществлено редактирование или создание объекта, а не удаление
                        const findIndex = this.appeal.subjects.findIndex(item => item.guid === subject.guid);
                        this.appeal.subjects[findIndex] = Object.assign(this.appeal.subjects[findIndex], subject);
                        // Обновление данных в сервисе
                        if (
                            this.appealSubservicesService.entitiesData &&
                            this.appealSubservicesService.entitiesData[subject.guid]
                        ) {
                            this.appealSubservicesService.entitiesData[subject.guid] = Object.assign(
                                this.appealSubservicesService.entitiesData[subject.guid],
                                subject,
                            );
                        }
                        this.updateElementInBaseAppeal(subject, 'subjects');
                    }
                } else {
                    error = subjectData.error;
                }
            });
            if (error) {
                // Если при сохранении хотя бы одного элемента произошла ошибка, обрываем процедуру сохранения и возвращаем ошибку
                this.isProcessSavingAppeal = false;

                return Promise.reject(error);
            } else {
                // Инициализация сохранения файлов документов дела
                return this.saveAppealObjects();
            }
        });
    }

    /**
     * Окончательное сохранение объектов и переход к сохранению документов
     */
    private saveAppealObjects() {
        // Получение набора промисов для измененных (только измененный состав полей) или добавленных объектов
        const promises = this._processAppealEntities(this.appeal, 'objects');

        return Promise.all(
            promises.map(saveResults =>
                saveResults.then(
                    success => ({ success }),
                    error => ({ error }),
                ),
            ),
        ).then(objects => {
            let error = null;
            objects.forEach(objectData => {
                if (objectData.success) {
                    // Данные объекта успешно сохранены, обновляем информацию в деле
                    const object = objectData.success;
                    if (object) {
                        // Если было осуществлено редактирование или создание объекта, а не удаление
                        const findIndex = this.appeal.objects.findIndex(item => item.guid === object.guid);
                        this.appeal.objects[findIndex] = Object.assign(this.appeal.objects[findIndex], object);
                        // Обновление данных в сервисе
                        if (
                            this.appealSubservicesService.entitiesData &&
                            this.appealSubservicesService.entitiesData[object.guid]
                        ) {
                            this.appealSubservicesService.entitiesData[object.guid] = Object.assign(
                                this.appealSubservicesService.entitiesData[object.guid],
                                object,
                            );
                        }
                        this.updateElementInBaseAppeal(object, 'objects');
                    }
                } else {
                    error = objectData.error;
                }
            });
            if (error) {
                // Если при сохранении хотя бы одного элемента произошла ошибка, обрываем процедуру сохранения и возвращаем ошибку
                this.isProcessSavingAppeal = false;

                return Promise.reject(error);
            } else {
                // Инициализация сохранения файлов документов дела
                return this.saveFilesInAppealDocuments();
            }
        });
    }

    /**
     * 1-ый этап сохранения документов: сохранение файлов
     */
    private saveFilesInAppealDocuments() {
        this.documentsWithFiles = {};
        // Инициализация массива документов, для которых требуется сохранение файлов
        const promises = [];
        this.appeal.documents.forEach(document => {
            const documentData = this.documentService.data[document.guid]; // расширенные данные работы с документов в сервисе
            // Если есть очередь загрузки, добавляем документ в проверочный объект
            if (documentData.queue && documentData.queue.length) {
                documentData.queue.forEach(file => {
                    // Если файл еще не сохранен на сервере
                    if (!file._id) {
                        promises.push(this.saveDocumentFile(document, file));
                    } else if (file.fromAppealId) {
                        promises.push(this.transferDocumentFileFromCopyAppeal(document, file));
                    } else if (file.signature) {
                        promises.push(this.saveDocumentFileContent(document, file));
                    }
                });
            }
        });

        return Promise.all(promises).then(
            () => {
                return this.saveEnvelopesInAppealDocuments();
            },
            error => {
                this.isProcessSavingAppeal = false;

                return Promise.reject(error);
            },
        );
    }

    private saveDocumentFileContent(document, file) {
        const baseDocument = this.baseAppeal.documents.find(item => item.guid === document.guid);
        const fileInBaseAppeal = baseDocument.files.find(item => item._id === file._id);

        const fileForSave: any = {};
        // Пробегаемся по полям файла
        Object.keys(file).forEach(item => {
            if (JSON.stringify(file[item]) !== JSON.stringify(fileInBaseAppeal[item])) {
                fileForSave[item] = file[item];
            }
        });
        if (Object.keys(fileForSave).length) {
            fileForSave._id = file._id;

            return this.restService.update('uploadedFiles', fileForSave);
        } else {
            return Promise.resolve();
        }
    }

    public transferDocumentFileFromCopyAppeal(document, file) {
        const uploadUrl = Config.server + Config.api + 'storage/copy';
        const fileIndex = document.files.findIndex(item => item._id === file._id);

        // Заполнение дополнительных данных дела
        const formData = new FormData();
        formData.append('entryName', this.mainCollection);
        formData.append('entryId', this.appeal._id);
        formData.append('fileId', file._id);

        // Сохранение файла на сервере
        return this.session.check().then(session => {
            // Формирование заголовков запроса
            const httpOptions = {
                headers: new HttpHeaders({
                    Authorization: 'Bearer ' + session.accessToken,
                    Accept: '*',
                }),
            };

            return this.http
                .post(uploadUrl, formData, httpOptions)
                .toPromise()
                .then(
                    savedFile => {
                        this.documentService.data[document.guid].queue[fileIndex] = savedFile;

                        return Promise.resolve(true);
                    },
                    () => {
                        return Promise.reject(
                            'При сохранении файлов документов произошла ошибка. Данные не были сохранены. Повторите операцию сохранения!',
                        );
                    },
                );
        });
    }

    /**
     * Сохранение отдельного файла документа ранее не сохраненного на сервере
     * @param document - документ которому принадлежит файл
     * @param file - сохраняемый файл
     * @returns {Promise<boolean>}
     */
    private saveDocumentFile(document, file) {
        const uploadUrl = Config.server + Config.api + 'storage/upload';

        // Заполнение дополнительных данных дела
        const formData = new FormData();
        formData.append('entryName', this.mainCollection);
        formData.append('entryId', this.appeal._id);
        formData.append('file', file, file.name);

        // Сохранение файла на сервере
        return this.session.check().then(session => {
            // Формирование заголовков запроса
            const httpOptions = {
                headers: new HttpHeaders({
                    Authorization: 'Bearer ' + session.accessToken,
                    Accept: '*',
                }),
            };

            return this.http
                .post(uploadUrl, formData, httpOptions)
                .toPromise()
                .then(
                    savedFile => {
                        if (!document.files) {
                            document.files = [];
                        }

                        document.files.push(savedFile); // Добавление сохраненного файла в документ

                        // Обновление информации о файле в сервисе
                        const fileIndex = this.documentService.data[document.guid].queue.findIndex(
                            item => item === file,
                        );
                        this.documentService.data[document.guid].queue[fileIndex] = savedFile;

                        return Promise.resolve(true);
                    },
                    () => {
                        return Promise.reject(
                            'При сохранении файлов документов произошла ошибка. Данные не были сохранены. Повторите операцию сохранения!',
                        );
                    },
                );
        });
    }

    /**
     * 2-й этап сохранения документов: - сохранение конвертов (запросов) внутри документов-запросов
     */
    private saveEnvelopesInAppealDocuments() {
        const promises = [];
        this.appeal.documents.forEach(document => {
            if (document.requestId) {
                promises.push(this.saveDocumentEnvelope(document));
            }
        });

        // После сохранения конвертов - переход на этап сохранения документов
        return Promise.all(promises).then(
            () => this.saveAppealDocuments(),
            error => {
                this.isProcessSavingAppeal = false;

                return Promise.reject(error);
            },
        );
    }

    /**
     * Сохранения конверта в документе-запросе дела
     * @param document
     * @returns {Promise<any>}
     */
    public saveDocumentEnvelope(document) {
        const documentData = this.documentService.data[document.guid];
        if (documentData.envelope) {
            const envelope = documentData.envelope;
            const baseEnvelope = documentData.baseEnvelope;
            if (!envelope.appealId) {
                envelope.appealId = this.appeal._id;
            }

            const envelopeForSave = this.compareFields(envelope, baseEnvelope);
            if (Object.keys(envelopeForSave).length > 0) {
                const promises = [];
                if (envelope._id) {
                    // Обновление данных конверта
                    promises.push(this.restService.update('envelopes', envelopeForSave));
                } else {
                    // Создание конверта
                    promises.push(this.restService.create('envelopes', envelopeForSave));
                }

                return Promise.all(promises).then(
                    envelopes => {
                        const savedEnvelope = envelopes[0];
                        const newEnvelope = Object.assign(envelope, savedEnvelope);
                        this.documentService.data[document.guid].envelope = newEnvelope;
                        this.documentService.data[document.guid].baseEnvelope = cloneDeep(newEnvelope);
                        document.envelopeId = savedEnvelope._id;

                        return Promise.resolve(newEnvelope);
                    },
                    error => {
                        return Promise.reject(error);
                    },
                );
            }
        } else {
            // Отсутствует envelope в документе-запросе
            return Promise.resolve(true);
        }
    }

    /**
     * 3-й этап сохранения данных (последний) - сохранение документов на сервере
     */
    private saveAppealDocuments() {
        const promises = this._processAppealEntities(this.appeal, 'documents');

        return Promise.all(
            promises.map(saveResults =>
                saveResults.then(
                    result => ({ success: result }),
                    error => ({ error: error }),
                ),
            ),
        ).then(
            documents => {
                let error = null;
                // Обновляем данные
                documents.forEach(documentData => {
                    if (documentData.success) {
                        const document = documentData.success;
                        if (document) {
                            const findIndex = this.appeal.documents.findIndex(item => item.guid === document.guid);
                            this.appeal.documents[findIndex] = merge(this.appeal.documents[findIndex], document);
                            // Переинициализация данных сервиса
                            this.documentService.data[document.guid] = merge(
                                this.documentService.data[document.guid],
                                document,
                            );
                            this.updateElementInBaseAppeal(document, 'documents');
                        }
                    } else {
                        error = documentData.error;
                    }
                });

                this.isProcessSavingAppeal = false;
                if (!error) {
                    // Если в процессе сохранения дело было создано, перенаправляем на страницу редактирования дела
                    if (this.isCreateAppealMode) {
                        this.router.navigate([this.moduleBaseUrl, this.baseUrl, 'edit', this.appeal._id]);
                    }

                    // Сохраняем обновленное дело, как базовое
                    this.baseAppeal = cloneDeep(this.appeal);

                    return Promise.resolve(this.appeal);
                } else {
                    return Promise.reject(error);
                }
            },
            error => {
                this.isProcessSavingAppeal = false;

                return Promise.reject(error);
            },
        );
    }

    // ---------------------- ФУНКЦИИ СОХРАНЕНИЯ ОТДЕЛЬНЫХ СУЩНОСТЕЙ -----------------//

    /**
     * Удаление документа
     * @param document - удаляемый документ
     */
    public deleteDocument(document) {
        // Если документ был ранее сохранен на сервере
        if (document.mainId) {
            this.restService.remove(this.appeal, document, 'documents').then(() => {
                this.deleteElementFromAppeal(document, 'documents');
                this.toaster.success('Документ успешно удален из дела');
            });
        } else {
            this.deleteElementFromAppeal(document, 'documents');
            this.toaster.success('Документ успешно удален из дела');
        }
    }

    /**
     * Удаление элемента из дела
     * @param element
     * @param type
     */
    public deleteElementFromAppeal(element, type) {
        const indexElementInAppeal = this.appeal[type].findIndex(item => item.guid === element.guid);
        // Удаление информации о элементе из структуры дела
        if (indexElementInAppeal !== -1) {
            this.appeal[type].splice(indexElementInAppeal, 1);
        }
        if (type === 'documents') {
            // Удаление информации из описательного объекта соответствующего сервиса
            delete this.documentService.data[element.guid];
        } else if (type === 'objects') {
            // удаляем информацию об объекте в настройках услуг
            if (!this.appeal.subservice.objects) {
                return;
            }

            const findIndex = this.appeal.subservice.objects.findIndex(item => item.guid === element.guid);
            if (findIndex !== -1) {
                this.appeal.subservice.objects.splice(findIndex, 1);
                // Удаление настроек объекта в услуге из соответствующего сервиса
                delete this.appealSubservicesService.data[this.appeal.subservice.id].objects[element.guid];
            }
            // Удаление ссылки на представителя в других объектах, если представителем является удаляемый объект
            const representativeIndex = this.appeal.subservice.objects.findIndex(
                item => item.representative && item.representative.guid === element.guid,
            );
            if (representativeIndex !== -1) {
                const objectWithRepresentativeGuid = this.appeal.subservice.objects[representativeIndex].guid;
                delete this.appeal.subservice.objects[representativeIndex].representative;
                delete this.appealSubservicesService.data[this.appeal.subservice.id].objects[
                    objectWithRepresentativeGuid
                ].representative;
            }
        }
        // Обновление информации
        this.updateElementInBaseAppeal(element, type, true);
    }

    /**
     * Сохранение элемента дела (конверты, сообщения)
     * @param before - объект до изменения
     * @param current - объект после изменения
     * @param collection - коллекция в БД
     */
    public saveAppealItem(before, current, collection) {
        const objectForSave = this.compareFields(current, before);
        if (Object.keys(objectForSave).length > 0) {
            if (objectForSave['_id']) {
                return this.restService.update(collection, objectForSave);
            } else {
                return this.restService.create(collection, objectForSave);
            }
        } else {
            return Promise.resolve({ noChange: true });
        }
    }

    /**
     * Удаление элемента дела с сервера
     * @param object - удаляемый объект
     * @param collection - коллекция в БД
     * @returns {any}
     */
    public deleteAppealItem(object, collection) {
        return this.restService.remove(collection, object);
    }

    // ----------------------------- СЛУЖЕБНЫЕ ФУНКЦИИ -------------------------------//
    public updateElementInBaseAppeal(element, type, isDelete = false) {
        if (!this.baseAppeal[type]) {
            this.baseAppeal[type] = [];
        }
        const elementIndexInAppeal = this.baseAppeal[type].findIndex(item => item.guid === element.guid);
        if (elementIndexInAppeal !== -1) {
            if (isDelete) {
                this.baseAppeal[type].splice(elementIndexInAppeal, 1);
                if (type === 'objects') {
                    this.baseAppeal.subservices.forEach(subservice => {
                        if (subservice.objects) {
                            const findObjectIndex = subservice.objects.findIndex(item => item.guid === element.guid);
                            if (findObjectIndex !== -1) {
                                subservice.objects.splice(findObjectIndex, 1);
                            }
                            // Удаление ссылки на представителя в других объектах, если представителем является удаляемый объект
                            const representativeIndex = subservice.objects.findIndex(
                                item => item.representative && item.representative.guid === element.guid,
                            );
                            if (representativeIndex !== -1) {
                                delete subservice.objects[representativeIndex].representative;
                            }
                        }
                    });
                }
            } else {
                this.baseAppeal[type][elementIndexInAppeal] = element;
            }
        } else {
            this.baseAppeal[type].push(element);
        }
    }

    /**
     * Формирование массива промисов создания/обновления объектов/документов
     * @param appeal - обрабатываемео дело
     * @param type - тип элементов: объекты/документы (objects/documents)
     * @returns {Array}
     */
    private _processAppealEntities(appeal, type) {
        const promises = [];
        const items: any = this._compareEntities(appeal, type);
        if (items.added.length) {
            items.added.forEach(item => {
                promises.push(this.restService.create(appeal, item, type));
            });
        }
        if (items.changed.length) {
            items.changed.forEach(item => {
                promises.push(this.restService.update(appeal, item, type));
            });
        }
        if (items.deleted.length) {
            items.deleted.forEach(item => {
                promises.push(this.restService.remove(appeal, item, type));
            });
        }
        if (items.single.added.length) {
            items.single.added.forEach(item => {
                promises.push(this.restService.create(appeal, item, type));
            });
        }
        if (items.single.changed.length) {
            items.single.changed.forEach(item => {
                promises.push(this.restService.update(appeal, item, type));
            });
        }

        return promises;
    }

    /**
     * Сравнение полей двух элементов
     * @param newItem - измененный объект
     * @param oldItem - предыдущая версия объекта
     * @returns {{}}
     */
    private compareFields(newItem, oldItem) {
        let resultItem: any = {};
        if (!oldItem) {
            resultItem = cloneDeep(newItem);
        } else {
            Object.keys(newItem).forEach(field => {
                if (!isEqual(newItem[field], oldItem[field])) {
                    resultItem[field] = newItem[field];
                }
            });
            if (Object.keys(resultItem).length) {
                if (newItem.mainId) {
                    resultItem.mainId = newItem.mainId;
                }
                if (newItem.auid) {
                    resultItem.auid = newItem.auid;
                }
                if (newItem._id) {
                    resultItem._id = newItem._id;
                }
                if (newItem.guid) {
                    resultItem.guid = newItem.guid;
                }
            }
        }

        return resultItem;
    }

    /**
     * Сравнение сущностей, и возврат только изменненного состава полей (для сохранения объектов и документов)
     * @param appeal
     * @param type
     * @returns - объект формата {added: массив добавленных элементов, changed - массив измененных элементов}
     */
    private _compareEntities(appeal, type) {
        const items = appeal[type] ? appeal[type] : [];
        const baseItems = this.baseAppeal[type] ? this.baseAppeal[type] : [];

        const added = [];
        const changed = [];
        const deleted = [];
        const single = {
            added: [],
            changed: [],
        };

        // в основном для subservice
        if (items.constructor.name !== 'Array') {
            const item = items;

            const compareItem = baseItems;
            if (compareItem && Object.keys(compareItem).length && !_.isEqual(item, compareItem)) {
                const changedItem: any = {};
                Object.keys(item).forEach(key => {
                    if (!_.isEqual(item[key], compareItem[key])) {
                        changedItem[key] = item[key];
                    }
                });
                if (Object.keys(changedItem).length) {
                    changedItem.guid = item.guid;
                    changedItem.mainId = item.mainId;
                    let parentEntries;
                    switch (this.mainCollection) {
                        case 'licensingActivityAppeals':
                            parentEntries = 'licensingActivityAppeals.subservices';
                            break;
                        case 'appeals':
                            parentEntries = 'appeals.subservices';
                            break;
                        default:
                            parentEntries = 'spoAppeals.subservices';
                            break;
                    }
                    changedItem.parentEntries = parentEntries;
                    single.changed.push(changedItem);
                }
            } else if (!(compareItem && Object.keys(compareItem).length)) {
                single.added.push(item);
            }
        }

        if (items.constructor.name === 'Array' && items.length) {
            items.forEach(item => {
                if (type === 'subjects' && item.fieldRequirements) {
                    item.fieldRequirements = null;
                }
                const compareItem = baseItems.find(elm => elm.guid === item.guid);
                if (compareItem && !isEqual(item, compareItem)) {
                    const changedItem: any = {};
                    Object.keys(item).forEach(key => {
                        if (!isEqual(item[key], compareItem[key])) {
                            changedItem[key] = item[key];
                        }
                    });
                    if (Object.keys(changedItem).length) {
                        changedItem.guid = item.guid;
                        changedItem.mainId = item.mainId;
                        changed.push(changedItem);
                    }
                } else if (!compareItem) {
                    added.push(item);
                }
            });
        }

        // Определяем удаленные элементы
        if (baseItems.length) {
            baseItems.forEach(item => {
                const currentItem = items.find(elm => elm.guid === item.guid);
                // Если элемент в текущем сохраняемом деле отсутствует, но у него в базовом деле есть mainId (т.е. был сохранен на сервере)
                if (!currentItem && item.mainId) {
                    deleted.push(item);
                }
            });
        }

        return { added, changed, deleted, single };
    }

    /**
     * Запуск процедуры сохранения (обновления/добавления) объектов в реестре и передача в функцию дальнейшего сохранения объектов и документов
     */
    public saveObjectsInReestr() {
        let promises;
        promises = this.processSaveObjectInReestr();

        return Promise.all(promises).then(reestrResolves => {
            const problemObjects = [];
            reestrResolves.forEach((item: any) => {
                if (!item.complete) {
                    problemObjects.push(item);
                } else {
                }
            });

            return Promise.resolve(true);
        });
    }

    public processSaveObjectInReestr() {
        const promises = [];
        const processingAppeal = this.metaReglament ? null : this.appeal;

        // сохранение субъектов (кроме физиков)
        this.appeal.subjects.forEach(subject => {
            if (subject.specialTypeId !== 'individualApplicant') {
                // promises.push(this.personService.newSaveObjectInReestr(item, this.appeal, 'subjectsKno'));
                promises.push(this.personService.saveEntityInReestr(subject, 'subjectsKno', processingAppeal));
            }
        });

        this.appeal.objects.forEach(object => {
            promises.push(this.personService.saveEntityInReestr(object, 'objectsKno', processingAppeal));
            // promises.push(this.personService.newSaveObjectInReestr(item, this.appeal, 'objectsKno'));
        });

        return promises;
    }

    /**
     * Корректировка данных сервисов при удалении элементов дела (услуг, объектов, документов)
     */
    public correctServiceDataAfterDeleteAppealEntity() {
        let needResaveAppeal = false;
        //  Обработка удаления услуг в деле
        const subservicesData = {};
        let hasDeletedSubservice = false;
        Object.keys(this.appealSubservicesService.data).forEach(appealSubserviceGuid => {
            hasDeletedSubservice = this.appeal.subservice.guid !== appealSubserviceGuid;
            if (!hasDeletedSubservice) {
                subservicesData[appealSubserviceGuid] = this.appealSubservicesService.data[appealSubserviceGuid];
            }
        });
        // Были удаления услуг из дела
        if (hasDeletedSubservice) {
            this.appealSubservicesService.data = subservicesData;
            // Обновление данных в сервисе документов
            this.documentService.reInitSubserviceData();
            const hasUnusedDocuments = this.documentService.correctSubserviceDocGroups();
            if (hasUnusedDocuments) {
                needResaveAppeal = true;
            }
        }

        // Обработка удаления объектов в деле
        const existObjectsGuids = [];
        this.appeal.objects.forEach(object => {
            existObjectsGuids.push(object.guid);
        });
        const objectsData = {};
        let hasDeletedObject = false;
        Object.keys(this.appealSubservicesService.entitiesData).forEach(objectGuid => {
            if (existObjectsGuids.indexOf(objectGuid) !== -1) {
                objectsData[objectGuid] = this.appealSubservicesService.entitiesData[objectGuid];
            } else {
                hasDeletedObject = true;
            }
        });
        if (hasDeletedObject) {
            this.appealSubservicesService.entitiesData = objectsData;
            // Пробегаемся по настройкам объектах в услугах и удаляем информацию об удаленных объектах
            Object.keys(this.appealSubservicesService.data).forEach(appealSubserviceGuid => {
                const appealSubserviceObjects = {};
                if (
                    this.appealSubservicesService.data[appealSubserviceGuid].objects &&
                    Object.keys(this.appealSubservicesService.data[appealSubserviceGuid].objects).length > 0
                ) {
                    Object.keys(this.appealSubservicesService.data[appealSubserviceGuid].objects).forEach(
                        objectGuid => {
                            if (existObjectsGuids.indexOf(objectGuid) !== -1) {
                                appealSubserviceObjects[objectGuid] =
                                    this.appealSubservicesService.data[appealSubserviceGuid].objects[objectGuid];
                            }
                        },
                    );
                    this.appealSubservicesService.data[appealSubserviceGuid].objects = appealSubserviceObjects;
                }

                // Удаляем настройки привязки объектов к субъектам
                Object.keys(this.appealSubservicesService.data[appealSubserviceGuid].subjects).forEach(subjectGuid => {
                    this.appealSubservicesService.data[appealSubserviceGuid].subjects[subjectGuid].objects =
                        this.appealSubservicesService.data[appealSubserviceGuid].subjects[subjectGuid].objects.filter(
                            item => existObjectsGuids.indexOf(item.guid) !== -1,
                        );
                });
            });

            // Обновление данных в сервисе документов
            this.documentService.reInitSubserviceData();

            const hasUnusedDocuments = this.documentService.correctSubserviceDocGroups();
            if (hasUnusedDocuments) {
                needResaveAppeal = true;
            }
        }

        // Обработка удаления субъектов в деле
        const existSubjectsGuids = [];
        this.appeal.subjects.forEach(subject => {
            existSubjectsGuids.push(subject.guid);
        });
        const subjectsData = {};
        let hasDeletedSubjects = false;
        Object.keys(this.appealSubservicesService.entitiesData).forEach(subjectGuid => {
            if (existSubjectsGuids.indexOf(subjectGuid) !== -1) {
                subjectsData[subjectGuid] = this.appealSubservicesService.entitiesData[subjectGuid];
            } else {
                hasDeletedSubjects = true;
            }
        });
        if (hasDeletedSubjects) {
            this.appealSubservicesService.entitiesData = subjectsData;
            // Пробегаемся по настройкам объектах в услугах и удаляем информацию об удаленных объектах
            Object.keys(this.appealSubservicesService.data).forEach(appealSubserviceGuid => {
                const appealSubserviceSubjects = {};
                if (
                    this.appealSubservicesService.data[appealSubserviceGuid].subjects &&
                    Object.keys(this.appealSubservicesService.data[appealSubserviceGuid].subjects).length > 0
                ) {
                    Object.keys(this.appealSubservicesService.data[appealSubserviceGuid].subjects).forEach(
                        subjectGuid => {
                            if (existSubjectsGuids.indexOf(subjectGuid) !== -1) {
                                appealSubserviceSubjects[subjectGuid] =
                                    this.appealSubservicesService.data[appealSubserviceGuid].subjects[subjectGuid];
                            }
                        },
                    );
                    this.appealSubservicesService.data[appealSubserviceGuid].subjects = appealSubserviceSubjects;
                }

                // Удаляем настройки привязки субъектов к объектам
                Object.keys(this.appealSubservicesService.data[appealSubserviceGuid].objects).forEach(objectGuid => {
                    this.appealSubservicesService.data[appealSubserviceGuid].objects[objectGuid].objects =
                        this.appealSubservicesService.data[appealSubserviceGuid].objects[objectGuid].subjects.filter(
                            item => existSubjectsGuids.indexOf(item.guid) !== -1,
                        );
                });
            });
        }

        const documentsData = {};
        let hasDeletedDocument = false;
        Object.keys(this.documentService.data).forEach(documentGuid => {
            const find = this.appeal.documents.find(item => item.guid === documentGuid);
            if (find) {
                let notUse = false;
                const documentInfo = this.documentService.data[documentGuid];
                if (documentInfo.subserviceGuid) {
                    if (this.appeal.subservice.guid !== documentInfo.subserviceGuid) {
                        notUse = true;
                        hasDeletedDocument = true;
                    }
                }
                if (documentInfo.resultSubserviceLink) {
                    // Услуга была удалена
                    if (this.appeal.subservice.guid !== documentInfo.resultSubserviceLink.guid) {
                        documentInfo.resultSubserviceLink = null;
                    }
                }
                if (!notUse) {
                    documentsData[documentGuid] = documentInfo;
                }
            } else {
                hasDeletedDocument = true;
            }
        });
        this.documentService.data = documentsData;

        if (needResaveAppeal) {
            return this.saveAppeal().then(() => {
                return this.correctServiceDataAfterDeleteAppealEntity();
            });
        } else {
            return Promise.resolve(true);
        }
    }

    /**
     * Обновление данных запроса в статусе "Отправлен"
     * @param document - документ-запрос
     */
    public async updateEnvelopeData(document) {
        const envelopeId = this.documentService.data[document.guid].envelope._id;
        const envelope = await this.restService.find('envelopes', envelopeId);
        this.documentService.data[document.guid].envelope = cloneDeep(envelope);
        // Обновление информации в сравнительном объекте для избежания ненужных сохранений (для избежания затирки актуальных данных)
        this.documentService.data[document.guid].baseEnvelope = cloneDeep(envelope);
        this.documentService.calculateDocumentPermissions(document.guid);

        // Получение и обновление информации о файлах документа (их состав обновляется в процессе обработки запроса-конверта)
        const collection = this.appeal.subservice.appealsCollection
            ? this.appeal.subservice.appealsCollection
            : 'appeals';
        const parentEntries = collection + '.documents';
        try {
            const serverDocument: any = await this.restService.findChild(
                collection,
                'documents',
                this.appeal._id,
                document.guid,
                parentEntries,
            );
            const indexDoc = this.appeal.documents.findIndex(item => serverDocument.guid === item.guid);
            if (serverDocument.files && serverDocument.files.length > 0) {
                this.appeal.documents[indexDoc].files = clone(serverDocument.files);
                this.documentService.data[document.guid].queue = clone(serverDocument.files);
            }
            this.toaster.success('Данные документа-запроса успешно обновлены');
        } catch (error) {
            const errorText = 'Произошла ошибка при обновлении документа:  ' + error;
            this.toaster.html(errorText);
            await this.errorLoggingService.log(this.errorLoggingService.SPO, new Error(errorText));
        }
    }
}
