// Коллекции
export const APPEALS_COLLECTION = 'appeals';
export const SUBSERVICES_COLLECTION = 'subservices';
export const SUBSERVICES_ARCHIVE_COLLECTION = 'subservices_archive';
export const STANDARDS_COLLECTION = 'standards';
export const USERS_COLLECTION = 'users';
export const ENVELOPES_COLLECTION = 'envelopes';
export const CONTROL_OPERATORS_COLLECTION = 'controlOperators';
export const ORGANIZATIONS = 'organizations';
export const MAPPING_FIELDS_COLLECTION = 'MappingFields';
export const FRONTEND_FILTERS_COLLECTION = 'frontendFilters';
export const REQUESTS_COLLECTION = 'requests';
export const KOLIBRI_RECORDS = 'kolibriRecords';
export const SERVICES_COLLECTION = 'services';
export const CAMUNDA_BUSINESS_INFO_COLLECTION = 'camundaBusinessInfo';

// разделы дела
export const SUBSERVICE_SECTION = 'subservice';
export const SUBJECTS_SECTION = 'subjects';
export const DOCUMENTS_SECTION = 'documents';

// Категории документов
export const SED_PRAKTIKA_DOCUMENT_NAME = 'Создание проекта документа в СЭД Практика';
export const NEXT_SED_PRAKTIKA_DOCUMENT_VERSION_NAME = 'Создание следующей версии проекта документа в СЭД Практика';

// Режимы отображения форм
export const VIEW_MODE = 'view';
export const EDIT_MODE = 'edit';

// urls
export const CONTROL_OPERATORS_URL = 'control-operators/';

// Режимы отображения форм
export enum MODES {
    VIEW,
    LOAD_FILE,
    EDIT,
}

export const DEFAULT_SORT_FIELD = 'datePlaneFinish';
export const DEFAULT_SPO_SORT_FIELD = 'dateCreation';

export enum LOAD_DATA_MODES {
    REPLACE,
    ADD,
}

// Роли пользователей

export const SPECIALIST_ROLE = 'specialist';
export const ADMIN_ROLE = 'superAdministrator';

// Фильтры
export const APPEALS_ACTUALITY = 'appealsActuality';

export const ACTUALITY_STATUS = {
    ACTUAL: 'appeals:actual',
    COMPLETE: 'appeals:complete',
    PREARCHIVE: 'appealsPreArchive',
    ARCHIVE: 'appealsArchive',
};

export const APPEAL_STATUSES = {
  DRAFT: 'draft',
  PROCESS: 'process',
};

export const APPEALS_STATUS = 'appealStatuses';
export const APPEALS_SOURCE = 'appealSource';

export const STATUS_CODE = 'status.code';
export const DATE_FINISH = 'dateFinish';

// ключи localsStorage
export const CURRENT_ORGANIZATION = 'currentOrganization';

// статусы адаптеров
export const OFF = 'OFF';
export const SMEVCLIENT = 'SMEVCLIENT';
export const UNIVERSAL = 'UNIVERSAL';

// статусы запросов
export const SENT_ENVELOPE_STATUS = 'sended';
export const APPEALS_DEFAULT_LIST_SIZE = 20;
export const DEFAULT_APPEALS_PROJECTION = 'appealList';

export const ASCENDING_ORDER = 'ASC';
export const DESCENDING_ORDER = 'DESC';

// фильтры
export const STATUS_FILTER_NAME = 'status.code';
export const SUBSERVICES_STATUS_FILTER_NAME = 'subservices.status.code';

export const ACTIVE_TASK_STATUS = 'ACTIVE';
export const COMPLETED_TASK_STATUS = 'COMPLETED';
